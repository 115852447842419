import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'
import {downTemplate} from '@/utils/download'

/**
 * 设备分页查询
 * @param params
 */
export function getDevicePage(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/device/page',
        method: 'get',
        params
    })
}

/**
 * 查询项目标签
 * @param params
 */
export function getLoopUpItem(params) {
    return request({
        url: envInfo.bgApp.lookupPath + '/lookup/item/find/list',
        method: 'get',
        params
    })
}

/**
 * 创建设备
 * @param params
 */
export function createDevice(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/device/create',
        method: 'post',
        data: params
    })
}

/**
 * 更新设备
 * @param params
 */
export function updateDevice(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/device/update',
        method: 'post',
        data: params
    })
}

/**
 * 删除设备
 * @param params
 */
export function deleteDeviceById(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/device/delete/'+params.deviceId,
        method: 'delete'
    })
}

/**
 * 查询设备信息
 * @param params
 */
export function findDeviceById(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/device/get/'+params.deviceId,
        method: 'get'
    })
}

/**
 * 批量删除设备
 * @param params
 */
export function batchDeleteDevice(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/device/batchDelete',
        method: 'delete',
        params
    })
}

/**
 * 导出设备
 * @param params
 */
export function exportDevice(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/device/export',
        method: 'post',
        data: params
    })
}

/**
 * 根据项目ID查部门
 * @param {*} params
 */
export function getDeptByProject(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/project/findDeptListByProject',
        method: 'get',
        params
    })
}

/**
 * 导出监控点
 * @param params
 */
export function exportMonitor(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/monitor/export',
        method: 'post',
        data: params
    })
}

/**
 * 下载设备模版
 */
export function downDeviceTemplate() {
    downTemplate("ruge.device", envInfo.bgApp.basePath);
}

/**
 * 下载监控点模版
 */
export function downMonitorTemplate() {
    downTemplate("ruge.monitor", envInfo.bgApp.basePath);
}

/**
 * 位置列表查询
 * @param params
 */
export function getPositionList() {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/position/list',
        method: 'get',
    })
}

/**
 * 设备图标分页查询
 * @param params
 */
export function getDeviceIconPage(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/device/iconPage',
        method: 'get',
        params
    })
}

/**
 * 查询设备类型对应图标
 * @param params
 */
export function getDeviceIconByType(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/device/getIcon',
        method: 'get',
        params
    })
}

/**
 * 创建设备图标
 * @param params
 */
export function createDeviceIcon(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/device/createIcon',
        method: 'post',
        data: params
    })
}

/**
 * 更新设备图标
 * @param params
 */
export function updateDeviceIcon(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/device/updateIcon',
        method: 'post',
        data: params
    })
}

/**
 * 删除设备图标
 * @param params
 */
export function deleteDeviceIcon(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/device/deleteIcon/'+params.deviceType,
        method: 'delete'
    })
}

//***************** 监控点 *************************
/**
 * 监控点分页查询
 * @param params
 */
export function getMonitorPage(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/monitor/page',
        method: 'get',
        params
    })
}

/**
 * 创建监控点
 * @param params
 */
export function createMonitor(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/monitor/create',
        method: 'post',
        data: params
    })
}

/**
 * 更新监控点
 * @param params
 */
export function updateMonitor(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/monitor/update',
        method: 'post',
        data: params
    })
}

/**
 * 删除监控点
 * @param params
 */
export function deleteMonitor(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/monitor/delete/'+params.monitorId,
        method: 'delete'
    })
}

export function findControlSource(){
    return request({
        url: envInfo.bgApp.basePath + '/tenant/controlsource/list',
        method: 'get'
    })
}

/**
 * 查询位置树
 */
export function getPositionTree(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/position/findPositionTree',
        method: 'get',
        params
    })
}


//***************** 设备隔离 *************************
/**
 * 设备分页查询
 * @param params
 */
export function getDeviceIsolatePage(params) {
    return request({
        url: envInfo.bgApp.basePath + '/tenant/device/isolatePage',
        method: 'get',
        params
    })
}

/**
 * 设备隔离
 * @param params
 */
export function isolateDevice(params) {
    return request({
        url: envInfo.bgApp.pumpPath + '/tenant/alarm/isolateDevice',
        method: 'post',
        data: params
    })
}

/**
 * 批量设备隔离
 * @param params
 */
export function batchIsolateDevice(params) {
    return request({
        url: envInfo.bgApp.pumpPath + '/tenant/alarm/batchIsolateDevice',
        method: 'post',
        data: params
    })
}

/**
 * 取消隔离
 * @param params
 */
export function cancelIsolateDevice(params) {
    return request({
        url: envInfo.bgApp.pumpPath + '/tenant/alarm/cancelIsolateDevice',
        method: 'post',
        data: params
    })
}

/**
 * 批量取消隔离
 * @param params
 */
export function batchCancelIsolateDevice(params) {
    return request({
        url: envInfo.bgApp.pumpPath + '/tenant/alarm/batchCancelIsolateDevice',
        method: 'post',
        data: params
    })
}
