<template>
  <div
    class="app-container custom-template-list"
    v-loading="loading"
    :style="{
      width: sidebar.opened ? 'calc(100vw - 330px)' : 'calc(100vw - 140px)',
    }"
  >
    <div class="header-line">
      <i
        v-if="showSider"
        class="el-icon-arrow-left arrow-icon"
        @click="setTransformX('left')"
      ></i>
      <div class="tabs-part">
        <div
          class="tabs-container"
          :style="{
            transform: `translateX(${translateX}px)`,
          }"
        >
          <span
            :class="['single-tab', item.active && 'single-tab-active']"
            v-for="item in templateList"
            :key="item.itemId"
            @click="setTabActive(item)"
            :title="item.itemName"
          >
            {{ item.itemName }}
          </span>
        </div>
      </div>
      <i
        v-if="showSider"
        class="el-icon-arrow-right arrow-icon"
        @click="setTransformX('right')"
      ></i>
      <div class="dropdown-part">
        <r-button plain @click="addNewHandler">新增模板</r-button>
      </div>
    </div>
    <div class="card-list" v-on:scroll="containerScroll">
      <div
        class="group-container"
        :ref="`group_${item.itemId}`"
        :id="`group_${item.itemId}`"
        v-for="item in templateList"
        :key="item.itemCode"
      >
        <div class="group-label">
          {{ item.itemName }}
        </div>
        <div class="no-card" v-if="!item.list.length">暂无数据</div>
        <div class="card-container" v-else>
          <el-row :gutter="30" style="width: 100%">
            <el-col
              :span="8"
              v-for="(single, singleIndex) in item.list"
              :key="single.itemId"
            >
              <TemplateCard
                :dataset="single"
                :index="singleIndex"
                :templateList="templateList"
                ref="singleCardRef"
                @success="initDatas"
                @toDetail="jumpToDetail"
              />
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTemplateManageList } from "@/api/ruge/bms/indicatorManagement";
import { getLoopUpItem } from "@/api/business/base/tenant/device";
import TemplateCard from "./components/templateCard.vue";
import { mapGetters } from "vuex";

export default {
  name: "custom-template-list",
  components: {
    TemplateCard,
  },
  data() {
    return {
      showSider: false,
      templateList: [],
      translateX: 0,
      tabsDiff: 0,
      scrollLock: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["sidebar"]),
  },
  mounted() {
    this.initDatas();
    this.$eventBus.$off("template-popover-close");
    this.$eventBus.$on("template-popover-close", (templateId) => {
      this.clearPopover(templateId);
    });
  },
  methods: {
    jumpToDetail(templateId, templateName, type) {
      this.$router.push({
        path: "/bms/templateEdit",
        query: {
          templateId,
          layout: "hide",
          templateName,
          templateGroup: this.getGroupNameById(templateId),
          type,
        },
      });
    },
    getGroupNameById(templateId) {
      for (let item of this.templateList) {
        for (let single of item.list) {
          if (single.templateId === templateId) {
            return item.itemName;
          }
        }
      }
    },
    addNewHandler() {
      this.$router.push({
        path: "/bms/templateEdit",
        query: {
          layout: "hide",
          templateType: this.templateList.filter((item) => item.active)[0]
            .itemCode,
        },
      });
    },
    clearPopover(templateId) {
      if (this.$refs.singleCardRef && this.$refs.singleCardRef.length) {
        this.$refs.singleCardRef.forEach((ins) => {
          ins.popoverClose(templateId);
        });
      }
    },
    containerScroll() {
      this.clearPopover();
      if (this.scrollLock) return;
      const containerDom = document.getElementsByClassName("card-list")[0];
      const offsetTop = containerDom.scrollTop;
      if (
        containerDom.scrollTop + containerDom.clientHeight ==
        containerDom.scrollHeight
      ) {
        this.setActiveByIndex(this.templateList.length - 1);
        return;
      }
      // 计算子元素高度区间
      // [248,664,336]
      const heightList = [];
      this.templateList.forEach((item, index) => {
        // const currentItem = this.$refs[`group_${item.groupId}`][0].$el;
        const currentItem = document.getElementById(`group_${item.itemId}`);
        const currentHeight = currentItem.offsetHeight;
        const preHeight = heightList[index - 1] || 0;
        heightList.push(currentHeight + preHeight);
      });
      for (let i = 0; i < heightList.length; i++) {
        if (offsetTop <= heightList[i]) {
          this.setActiveByIndex(i);
          break;
        }
      }
    },
    setActiveByIndex(setIndex) {
      this.templateList.forEach((item, index) => {
        item.active = setIndex === index;
      });
      this.scrollTabIntoView(setIndex);
    },
    setTabActive({ itemId }) {
      this.scrollLock = true;
      let activeIndex = 0;
      this.templateList.forEach((item, index) => {
        if (item.itemId === itemId) {
          this.setScrollTopByGroupId(item.itemId);
          activeIndex = index;
        }
        item.active = item.itemId === itemId;
      });
      setTimeout(() => {
        this.scrollLock = false;
        this.scrollTabIntoView(activeIndex);
      }, 100);
    },
    scrollTabIntoView(activeIndex) {
      const oneTabWidth = 120;
      // const oneTabWidth =
      //   document.getElementsByClassName("single-tab")[0].offsetWidth;
      const containerWidth =
        document.getElementsByClassName("tabs-part")[0].offsetWidth;
      // 记录中间数
      const middleNumber = (containerWidth / oneTabWidth / 2).toFixed(0);
      if (activeIndex > middleNumber) {
        if (this.translateX <= -this.tabsDiff) return;
        this.translateX = 0 - oneTabWidth * (activeIndex - middleNumber);
      } else {
        this.translateX = 0;
      }
    },
    setScrollTopByGroupId(templateId) {
      try {
        const targetDom = document.getElementById(`group_${templateId}`);
        targetDom.scrollIntoView({});
      } catch (error) {
        console.log("定位卡片失败：", error);
      }
    },
    setScrollTopById(templateId) {
      console.log("templateId", templateId);
      this.$nextTick(() => {
        try {
          const targetDom = document.getElementById(
            `template_id_${templateId}`
          );
          targetDom.scrollIntoView({});
          // 移除查询参数 templateId
          const newQuery = { ...this.$route.query };
          delete newQuery.templateId;
          this.$router.replace({ query: newQuery });
          // 边框高亮
          targetDom.style.border = "1px solid #1A4CEC";
          setTimeout(() => {
            targetDom.style.border = `1px solid #eef2f6`;
          }, 2000);
        } catch (error) {
          console.log("定位卡片失败：", error);
        }
      });
    },
    setTransformX(direction) {
      // const oneStepWidth =
      //   document.getElementsByClassName("single-tab")[0].offsetWidth;
      if (direction === "left") {
        if (this.translateX >= 0) return;
        this.translateX += 200;
      } else {
        if (this.translateX <= -this.tabsDiff) return;
        this.translateX -= 200;
      }
    },
    calcTabsProps() {
      // tabs-part 获取tabs的容器的宽度
      // offsetWidth: 获取元素的宽度，包括边框和内边距
      // clientWidth: 获取元素的宽度，不包括边框和内边距，但包括滚动条
      const outerContainer = document.getElementsByClassName("tabs-part")[0];
      const innerContainer =
        document.getElementsByClassName("tabs-container")[0];
      this.showSider = innerContainer.offsetWidth > outerContainer.offsetWidth;
      this.tabsDiff = innerContainer.offsetWidth - outerContainer.offsetWidth;
    },
    async initDatas() {
      this.loading = true;
      const typeList = await getLoopUpItem({
        classifyCode: "BI_TEMPLATE_TYPE",
      });
      const templateList = await getTemplateManageList();
      this.templateList = typeList.map((typeItem, typeIndex) => {
        const { itemCode, itemName, itemId } = typeItem;
        const list = templateList.filter(
          (templateItem) => templateItem.templateType === itemCode
        );
        return {
          list,
          itemId,
          itemCode,
          itemName,
          active: typeIndex === 0,
        };
      });
      this.loading = false;
      const { templateId } = this.$route.query;
      if (templateId) {
        this.setScrollTopById(templateId);
      }
      this.$nextTick(() => {
        this.calcTabsProps();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.custom-template-list {
  // 解决用scrollIntoView父容器会滑动的问题
  position: fixed;
  width: calc(100vw - 330px);
  .header-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #f1f4f6;
    .arrow-icon {
      font-size: 20px;
      cursor: pointer;
      position: relative;
      top: -5px;
    }
    .tabs-part {
      width: calc(100% - 180px);
      overflow: hidden;
      position: relative;
      height: 37px;
      .tabs-container {
        transition: transform 0.2s;
        width: max-content;
        .single-tab {
          display: inline-block;
          text-align: center;
          font-weight: 600;
          font-size: 14px;
          color: #5d687c;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          position: relative;
          height: 37px;
          line-height: 25px;
          cursor: pointer;
          margin-right: 20px;
        }
        .single-tab-active {
          color: #252d3d;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 20px;
            height: 2px;
            background: #2a61ff;
            border-radius: 7px;
            position: absolute;
            bottom: 0;
            left: calc(50% - 10px);
          }
        }
      }
    }
    .dropdown-part {
      text-align: right;
      position: relative;
      top: -5px;
    }
  }
  .card-list {
    height: calc(100% - 60px);
    overflow: auto;
    .group-container {
      .group-label {
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 14px;
        color: #252d3d;
        margin-bottom: 16px;
        position: relative;
        padding: 5px 18px;
        background: linear-gradient(90deg, #eff3f7 0%, #ffffff 100%);
        border-radius: 2px;
        &::before {
          content: "";
          display: inline-block;
          width: 2px;
          height: 12px;
          background: #2a61ff;
          position: absolute;
          left: 9px;
          top: 6px;
        }
      }
      .no-card {
        font-weight: 400;
        font-size: 14px;
        color: #b5bece;
        margin: 10px 0 30px;
      }
      .card-container {
        margin-bottom: 20px;
      }
    }
  }
}
</style>