<template>
  <div class="template-cover-change">
    <el-dialog
      :visible.sync="dataset.show"
      :before-close="closeHandler"
      append-to-body
      width="704px"
      title="修改封面"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <el-form
        :model="coverPicture.form"
        :rules="coverPicture.rules"
        label-position="top"
        ref="coverForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="封面图片"
          prop="coverPicture"
          class="auto-height-item"
        >
          <div class="upload-container" v-loading="coverPicture.loading">
            <el-upload
              class="avatar-uploader"
              :action="uploadPath"
              :with-credentials="true"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div class="tips-container">
              <div>
                <div>
                  温馨提示：最多上传1张，图片大小不能超过2m，图片格式为jpg，png。
                </div>
                <!-- <div>图片尺寸212*112</div> -->
              </div>
              <div
                v-show="coverPicture.form.coverPicture"
                class="delete-icon"
                @click="deleteHandler"
              >
                <i class="el-icon-delete"></i>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div class="dialog-button-line">
        <r-button type="cancel" @click="closeHandler">取消</r-button>
        <r-button plain @click="coverChangeHandler()" :loading="submitLoading"
          >保存</r-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { envInfo } from "@/constants/envInfo";
import { changeTemplateCover } from "@/api/ruge/bms/indicatorManagement";
export default {
  name: "template-cover-change",
  props: {
    dataset: {
      type: Object,
      default() {
        return {
          show: false,
        };
      },
    },
  },
  data() {
    return {
      submitLoading: false,
      uploadPath:
        envInfo.bgApp.archivePath +
        "/param/archive/upload?ulType=DefaultUpload&scopeType=ALL",
      imageUrl: null,
      coverPicture: {
        form: {
          coverPicture: null,
        },
        rules: {
          coverPicture: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: "请上传封面",
            },
          ],
        },
        loading: false,
      },
    };
  },
  created() {
    this.setDefaultImage();
  },
  methods: {
    setDefaultImage() {
      const coverFileId = this.dataset.datas.coverPicture;
      if (coverFileId) {
        // 有设置cover
        this.imageUrl = `${envInfo.bgApp.archivePath}/param/archive/download?dlType=DefaultDownload&fType=image&fi=${coverFileId}`;
        this.coverPicture.form.coverPicture = coverFileId;
      }
    },
    async coverChangeHandler() {
      console.log("this.dataset.datas", this.dataset.datas);
      await this.$refs.coverForm.validate();
      this.submitLoading = true;
      const { coverPicture } = this.coverPicture.form;
      const { templateId } = this.dataset.datas;
      const params = {
        templateId,
        coverPicture,
      };
      changeTemplateCover(params)
        .then(() => {
          this.$message.success("修改成功！");
          this.$emit("close", 1);
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    deleteHandler() {
      this.coverPicture.form.coverPicture = null;
      this.imageUrl = null;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        this.$message.error("封面图片只能是 JPG,PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("封面图片大小不能超过 2MB!");
      }
      const returnFlag = (isJPG || isPNG) && isLt2M;
      this.coverPicture.loading = returnFlag;
      return returnFlag;
    },
    handleAvatarSuccess(files) {
      this.coverPicture.form.coverPicture = files[0].fileId;
      this.imageUrl =
        envInfo.bgApp.archivePath +
        "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
        files[0].fileId;
      this.coverPicture.loading = false;
    },
    closeHandler(freshFlag) {
      this.$emit("close", freshFlag);
    },
  },
};
</script>

<style lang="less">
.upload-container {
  display: flex;
  .tips-container {
    margin-left: 15px;
    font-size: 12px;
    & > div {
      height: 20px;
      line-height: 20px;
    }
    .delete-icon {
      position: absolute;
      bottom: 0;
      font-size: 14px;
      color: #ff0000;
      cursor: pointer;
    }
  }
}
.avatar-uploader {
  height: 177px;
  width: 300px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  height: 177px;
  width: 300px;
  line-height: 177px;
  text-align: center;
}
.avatar {
  height: 177px;
  width: 300px;
  display: block;
}
</style>