var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "template-rename-dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dataset.show,
            "before-close": _vm.closeHandler,
            width: "600px",
            title: "重命名",
            "append-to-body": "",
            "destroy-on-close": true,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dataset, "show", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "renameForm",
              attrs: {
                "label-position": "top",
                model: _vm.rename.form,
                rules: _vm.rename.formRule,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "模板名称", prop: "templateName" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "20", "show-word-limit": true },
                    model: {
                      value: _vm.rename.form.templateName,
                      callback: function ($$v) {
                        _vm.$set(_vm.rename.form, "templateName", $$v)
                      },
                      expression: "rename.form.templateName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dialog-button-line" },
            [
              _c(
                "r-button",
                {
                  attrs: { plain: "", loading: _vm.submitLoading },
                  on: {
                    click: function ($event) {
                      return _vm.renameHandler()
                    },
                  },
                },
                [_vm._v("\n        保存\n      ")]
              ),
              _c(
                "r-button",
                {
                  attrs: { type: "cancel" },
                  on: {
                    click: function ($event) {
                      return _vm.closeHandler(false)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }