var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "template-cover-change" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dataset.show,
            "before-close": _vm.closeHandler,
            "append-to-body": "",
            width: "704px",
            title: "修改封面",
            "destroy-on-close": true,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dataset, "show", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "coverForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.coverPicture.form,
                rules: _vm.coverPicture.rules,
                "label-position": "top",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "auto-height-item",
                  attrs: { label: "封面图片", prop: "coverPicture" },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.coverPicture.loading,
                          expression: "coverPicture.loading",
                        },
                      ],
                      staticClass: "upload-container",
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            action: _vm.uploadPath,
                            "with-credentials": true,
                            "show-file-list": false,
                            "on-success": _vm.handleAvatarSuccess,
                            "before-upload": _vm.beforeAvatarUpload,
                          },
                        },
                        [
                          _vm.imageUrl
                            ? _c("img", {
                                staticClass: "avatar",
                                attrs: { src: _vm.imageUrl },
                              })
                            : _c("i", {
                                staticClass:
                                  "el-icon-plus avatar-uploader-icon",
                              }),
                        ]
                      ),
                      _c("div", { staticClass: "tips-container" }, [
                        _c("div", [
                          _c("div", [
                            _vm._v(
                              "\n                温馨提示：最多上传1张，图片大小不能超过2m，图片格式为jpg，png。\n              "
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.coverPicture.form.coverPicture,
                                expression: "coverPicture.form.coverPicture",
                              },
                            ],
                            staticClass: "delete-icon",
                            on: { click: _vm.deleteHandler },
                          },
                          [_c("i", { staticClass: "el-icon-delete" })]
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dialog-button-line" },
            [
              _c(
                "r-button",
                { attrs: { type: "cancel" }, on: { click: _vm.closeHandler } },
                [_vm._v("取消")]
              ),
              _c(
                "r-button",
                {
                  attrs: { plain: "", loading: _vm.submitLoading },
                  on: {
                    click: function ($event) {
                      return _vm.coverChangeHandler()
                    },
                  },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }