var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        width: "600px",
        visible: _vm.dataset.show,
        title: "生成链接",
        "append-to-body": "",
        "before-close": () => {
          _vm.dataset.show = false
        },
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$set(_vm.dataset, "show", $event)
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "releaseForm",
          attrs: { model: _vm.dataset, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "链接", prop: "link" } },
            [
              _c("el-input", {
                staticStyle: { width: "calc(100% - 85px)" },
                attrs: { readonly: "" },
                model: {
                  value: _vm.dataset.link,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataset, "link", $$v)
                  },
                  expression: "dataset.link",
                },
              }),
              _c(
                "r-button",
                {
                  staticStyle: { "margin-left": "15px" },
                  attrs: { type: "default", plain: "" },
                  on: { click: _vm.copoHandler },
                },
                [_vm._v("复制")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: { "text-align": "right", "padding-right": "8px" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "r-button",
            {
              attrs: { plain: "" },
              on: {
                click: () => {
                  _vm.dataset.show = false
                },
              },
            },
            [_vm._v("关 闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }