<template>
  <div
    class="single-template-card"
    :id="`template_id_${dataset.templateId}`"
    @click="$emit('toDetail', dataset.templateId, dataset.templateName, 'edit')"
  >
    <div class="img-line">
      <img
        :src="coverImageRender(dataset.coverPicture)"
        class="example-img"
        alt=""
      />
    </div>
    <div class="card-name-line">
      <span class="name-span">
        {{ dataset.templateName }}
      </span>
      <span class="more-span" @click.stop="popoverClick(dataset)">
        <el-popover
          ref="popover"
          popper-class="more-span-popover"
          placement="bottom-start"
          width="400"
          trigger="click"
        >
          <div class="cascader-container">
            <div v-for="item in cascaderOptions" :key="item.key">
              <div
                v-if="!(item.key === 'setShare' && dataset.canShared === 0)"
                :class="[
                  'cascader-first-level',
                  item.key === 'delete' && 'first-level-delete',
                  item.disable && 'first-level-disable',
                ]"
                @click="firstLevelClick(item)"
              >
                <span>
                  {{ item.label }}
                </span>
              </div>
            </div>
          </div>
          <el-button class="more-button" slot="reference"
            ><img
              class="more-icon"
              src="@/assets/images/bms/more_icon.png"
              alt=""
          /></el-button>
        </el-popover>
      </span>
    </div>
    <div class="bottom-line">
      <span class="name-and-date">
        <span>
          {{ dataset.updateUserName || "System" }}
        </span>
        <span>
          {{ dataset.lastUpdateDate | dateFormat("YYYY-MM-DD HH:mm:ss") }}
        </span>
      </span>
      <span class="is-default" v-if="dataset.isDefault === 'Y'"> 默认 </span>
    </div>
    <!-- 模板重命名 -->
    <TemplateRenameDialog
      @close="renameClose"
      :dataset="renameDialog"
      v-if="renameDialog.show"
    />
    <!-- 模板预览 -->
    <TemplateView :dataset="templateView" @close="templateViewClose" />
    <!-- 修改封面 -->
    <TemplateCoverChange
      v-if="coverPicture.show"
      :dataset="coverPicture"
      @close="coverDialogClose"
    />
    <!-- 生成链接 -->
    <GenerateLinkDialog
      :dataset="generateLink"
      @close="
        () => {
          generateLink.show = false;
        }
      "
    />
  </div>
</template>

<script>
const templateExampleImg = require("@/assets/images/custom_template_example.png");
import {
  templateDelete,
  templateSortTop,
  setDefaultTemplate,
} from "@/api/ruge/bms/indicatorManagement";
import TemplateRenameDialog from "./renameDialog.vue";
import TemplateView from "../../../vlink/dashboardEdit/components/templateView.vue";
import TemplateCoverChange from "./templateCoverChange.vue";
import { envInfo } from "@/constants/envInfo";
import GenerateLinkDialog from "./generateLinkDialog.vue";

export default {
  name: "single-template-card",
  components: {
    TemplateRenameDialog,
    TemplateView,
    TemplateCoverChange,
    GenerateLinkDialog,
  },
  props: {
    index: {
      type: Number,
    },
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
    templateList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      templateExampleImg,
      cascaderOptions: [
        {
          label: "预览",
          key: "preview",
        },
        {
          label: "设为默认",
          key: "setDefault",
        },
        {
          label: "重命名",
          key: "rename",
        },
        {
          label: "复制",
          key: "copy",
        },
        {
          label: "移到最前",
          key: "toTop",
          disable: this.index === 0,
        },
        {
          label: "修改封面",
          key: "changeCover",
        },
        {
          label: "生成链接",
          key: "generateLink",
        },
        {
          label: "删除",
          key: "delete",
        },
      ],
      renameDialog: {
        show: false,
        datas: {},
      },
      templateView: {
        show: false,
        templateId: null,
        templateName: null,
        appendToBody: true,
        from: "edit",
        manage: true,
      },
      coverPicture: {
        datas: {},
        show: false,
      },
      generateLink: {
        show: false,
        link: null,
      },
    };
  },
  methods: {
    coverImageRender(coverPicture) {
      return coverPicture
        ? `${envInfo.bgApp.archivePath}/param/archive/download?dlType=DefaultDownload&fType=image&fi=${coverPicture}`
        : templateExampleImg;
    },
    coverDialogClose(freshFlag) {
      this.coverPicture.show = false;
      freshFlag && this.$emit("success");
    },
    templateViewClose() {
      this.templateView.templateId = null;
      this.templateView.templateName = null;
      this.templateView.show = false;
    },
    popoverClick({ templateId }) {
      this.$eventBus.$emit("template-popover-close", templateId);
    },
    renameClose(freshFlag) {
      this.renameDialog.show = false;
      freshFlag && this.$emit("success");
    },
    async deleteHandler(templateId) {
      await this.$confirm(`确认删除此模板吗？`, this.$t("commons.warning"), {
        confirmButtonText: this.$t("commons.confirm"),
        cancelButtonText: this.$t("commons.cancel"),
        type: "warning",
      });
      templateDelete(templateId).then(() => {
        this.$message.success("删除成功！");
        this.$emit("success");
      });
    },
    popoverClose(templateId) {
      if (this.dataset.templateId !== templateId) {
        this.$refs.popover.doClose();
      }
    },
    toTopHandler() {
      const { templateId, templateType } = this.dataset;
      templateSortTop({ templateId, templateType }).then(() => {
        this.$message.success("移动成功！");
        this.$emit("success");
      });
    },
    getGroupNameById(templateId) {
      for (let item of this.templateList) {
        for (let single of item.list) {
          if (single.templateId === templateId) {
            return item.itemName;
          }
        }
      }
    },
    async setDefaultHandler() {
      const { templateId, templateType, templateName } = this.dataset;
      await this.$confirm(
        `确认将【${templateName}】设置为默认模板吗？`,
        "提示",
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "info",
        }
      );
      setDefaultTemplate({ templateId, templateType }).then(() => {
        this.$message.success("设置成功！");
        this.$emit("success");
      });
    },
    firstLevelClick(item) {
      if (item.disable) return;
      switch (item.key) {
        case "preview":
          this.templateView.templateId = this.dataset.templateId;
          this.templateView.templateName = this.dataset.templateName;
          this.templateView.show = true;
          break;
        case "setDefault":
          this.setDefaultHandler();
          break;
        case "rename":
          this.renameDialog.datas = this.dataset;
          this.renameDialog.show = true;
          break;
        case "copy":
          this.$router.push({
            path: "/bms/templateEdit",
            query: {
              layout: "hide",
              type: "copy",
              templateId: this.dataset.templateId,
              templateName: this.dataset.templateName,
              templateGroup: this.getGroupNameById(this.dataset.templateId),
            },
          });
          break;
        case "toTop":
          this.toTopHandler();
          break;
        case "changeCover":
          this.coverPicture.datas = this.dataset;
          this.coverPicture.show = true;
          break;
        case "generateLink":
          const { origin, pathname } = window.location;
          this.generateLink.show = true;
          this.generateLink.link = `${origin}${pathname}#/bms/commonDashboard?templateId=${this.dataset.templateId}`;
          break;
        case "delete":
          this.deleteHandler(this.dataset.templateId);
          break;
      }
      this.popoverClose();
    },
  },
};
</script>

<style lang="less" scoped>
.single-template-card {
  cursor: pointer;
  width: 100%;
  height: 309px;
  background: #ffffff;
  box-shadow: 0px 2px 5px 0px rgba(142, 142, 142, 0.1);
  border-radius: 10px;
  border: 1px solid #eef2f6;
  margin-bottom: 30px;
  .img-line {
    // border-bottom: 1px solid #eeeeee;
    overflow: hidden;
    &:hover {
      .example-img {
        transform: scale(1.2);
      }
    }
    .example-img {
      padding: 10px;
      height: 220px;
      width: 100%;
      transition: all 0.8s;
    }
  }
  .card-name-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    margin: 20px 0px;
    .name-span {
      font-family: PingFang SC, PingFang SC;
      font-weight: bold;
      font-size: 16px;
      color: #252d3d;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .more-span {
      display: inline-block;
      width: 28px;
      text-align: right;
      border-radius: 4px;
      .more-button {
        padding: 0;
        width: 28px;
        border: none;
      }
      .more-icon {
        cursor: pointer;
      }
      &:hover {
        background: #f5f5f6;
      }
    }
  }
  .bottom-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0px;
    padding: 0 12px;
    .name-and-date {
      font-weight: 400;
      font-size: 12px;
      color: #a7b4c7;
      span + span {
        margin-left: 8px;
      }
    }
    .flex-center {
      display: flex;
      align-items: center;
    }
    .is-default {
      display: inline-block;
      width: 28px;
      height: 16px;
      line-height: 16px;
      background: #2a61ff;
      border-radius: 4px;
      font-weight: 500;
      font-size: 10px;
      color: #ffffff;
      text-align: center;
      margin-left: 6px;
    }
  }
}
</style>
<style lang="less">
.more-span-popover {
  width: 117px !important;
  padding: 0;
  .cascader-container {
    .cascader-first-level {
      font-weight: 500;
      font-size: 14px;
      color: #152c5b;
      height: 30px;
      line-height: 30px;
      padding: 0 15px;
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:hover {
        color: #2a61ff;
        background: #f5f5f6;
        .cascader-second-container {
          display: block;
        }
      }
      .cascader-second-container {
        display: none;
        position: absolute;
        background: #ffffff;
        // height: 300px;
        overflow: auto;
        .cascader-second-level {
          font-weight: 500;
          font-size: 14px;
          color: #152c5b;
          height: 30px;
          line-height: 30px;
          padding: 0 15px;
          &:hover {
            background: #f5f5f6;
          }
        }
      }
      .cascader-top-left {
        top: -240px;
        left: -174px;
      }
      .cascader-bottom-left {
        top: 0;
        left: -175px;
      }
      .cascader-top-right {
        top: -239px;
        right: -175px;
      }
      .cascader-bottom-right {
        top: 0;
        right: -175px;
      }
    }
    .first-level-disable {
      color: #adadad;
      cursor: not-allowed;
      &:hover {
        color: #adadad;
      }
    }
    .first-level-delete {
      color: #ff0000;
      &:hover {
        color: #ff0000;
        background: #f5f5f6;
      }
    }
  }
}
</style>