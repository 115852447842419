var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "single-template-card",
      attrs: { id: `template_id_${_vm.dataset.templateId}` },
      on: {
        click: function ($event) {
          return _vm.$emit(
            "toDetail",
            _vm.dataset.templateId,
            _vm.dataset.templateName,
            "edit"
          )
        },
      },
    },
    [
      _c("div", { staticClass: "img-line" }, [
        _c("img", {
          staticClass: "example-img",
          attrs: {
            src: _vm.coverImageRender(_vm.dataset.coverPicture),
            alt: "",
          },
        }),
      ]),
      _c("div", { staticClass: "card-name-line" }, [
        _c("span", { staticClass: "name-span" }, [
          _vm._v("\n      " + _vm._s(_vm.dataset.templateName) + "\n    "),
        ]),
        _c(
          "span",
          {
            staticClass: "more-span",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.popoverClick(_vm.dataset)
              },
            },
          },
          [
            _c(
              "el-popover",
              {
                ref: "popover",
                attrs: {
                  "popper-class": "more-span-popover",
                  placement: "bottom-start",
                  width: "400",
                  trigger: "click",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "cascader-container" },
                  _vm._l(_vm.cascaderOptions, function (item) {
                    return _c("div", { key: item.key }, [
                      !(item.key === "setShare" && _vm.dataset.canShared === 0)
                        ? _c(
                            "div",
                            {
                              class: [
                                "cascader-first-level",
                                item.key === "delete" && "first-level-delete",
                                item.disable && "first-level-disable",
                              ],
                              on: {
                                click: function ($event) {
                                  return _vm.firstLevelClick(item)
                                },
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.label) +
                                    "\n              "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ])
                  }),
                  0
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "more-button",
                    attrs: { slot: "reference" },
                    slot: "reference",
                  },
                  [
                    _c("img", {
                      staticClass: "more-icon",
                      attrs: {
                        src: require("@/assets/images/bms/more_icon.png"),
                        alt: "",
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "bottom-line" }, [
        _c("span", { staticClass: "name-and-date" }, [
          _c("span", [
            _vm._v(
              "\n        " +
                _vm._s(_vm.dataset.updateUserName || "System") +
                "\n      "
            ),
          ]),
          _c("span", [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm._f("dateFormat")(
                    _vm.dataset.lastUpdateDate,
                    "YYYY-MM-DD HH:mm:ss"
                  )
                ) +
                "\n      "
            ),
          ]),
        ]),
        _vm.dataset.isDefault === "Y"
          ? _c("span", { staticClass: "is-default" }, [_vm._v(" 默认 ")])
          : _vm._e(),
      ]),
      _vm.renameDialog.show
        ? _c("TemplateRenameDialog", {
            attrs: { dataset: _vm.renameDialog },
            on: { close: _vm.renameClose },
          })
        : _vm._e(),
      _c("TemplateView", {
        attrs: { dataset: _vm.templateView },
        on: { close: _vm.templateViewClose },
      }),
      _vm.coverPicture.show
        ? _c("TemplateCoverChange", {
            attrs: { dataset: _vm.coverPicture },
            on: { close: _vm.coverDialogClose },
          })
        : _vm._e(),
      _c("GenerateLinkDialog", {
        attrs: { dataset: _vm.generateLink },
        on: {
          close: () => {
            _vm.generateLink.show = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }