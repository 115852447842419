<template>
  <el-dialog
    :close-on-click-modal="false"
    width="600px"
    :visible.sync="dataset.show"
    title="生成链接"
    append-to-body
    :before-close="
      () => {
        dataset.show = false;
      }
    "
  >
    <el-form ref="releaseForm" :model="dataset" label-position="top">
      <el-form-item label="链接" prop="link">
        <el-input
          style="width: calc(100% - 85px)"
          readonly
          v-model="dataset.link"
        ></el-input>
        <r-button
          type="default"
          plain
          @click="copoHandler"
          style="margin-left: 15px"
          >复制</r-button
        >
      </el-form-item>
    </el-form>
    <div slot="footer" style="text-align: right; padding-right: 8px">
      <r-button
        plain
        @click="
          () => {
            dataset.show = false;
          }
        "
        >关 闭</r-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { copy2Clip } from "@/utils/global";

export default {
  props: {
    dataset: {
      type: Object,
      default() {
        return {
          show: false,
        };
      },
    },
  },
  methods: {
    copoHandler() {
      copy2Clip(this.dataset.link);
    },
  },
};
</script>

<style>
</style>