<template>
  <div class="template-rename-dialog">
    <el-dialog
      :visible.sync="dataset.show"
      :before-close="closeHandler"
      width="600px"
      title="重命名"
      append-to-body
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <el-form
        label-position="top"
        :model="rename.form"
        ref="renameForm"
        :rules="rename.formRule"
      >
        <el-form-item label="模板名称" prop="templateName">
          <el-input
            v-model="rename.form.templateName"
            maxlength="20"
            :show-word-limit="true"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-button-line">
        <r-button plain @click="renameHandler()" :loading="submitLoading">
          保存
        </r-button>
        <r-button type="cancel" @click="closeHandler(false)">取消</r-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { templateRename } from "@/api/ruge/bms/indicatorManagement";
export default {
  name: "template-rename-dialog",
  props: {
    dataset: {
      type: Object,
      default() {
        return {
          show: false,
          datas: {},
        };
      },
    },
  },
  watch: {
    dataset: {
      handler(data) {
        this.rename.form.templateName = data.datas.templateName;
      },
      immediate: true,
    },
  },
  data() {
    return {
      submitLoading: false,
      rename: {
        show: false,
        formRule: {
          templateName: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
        },
        form: {
          templateName: null,
        },
      },
    };
  },
  methods: {
    closeHandler(freshFlag) {
      this.$emit("close", freshFlag);
    },
    async renameHandler() {
      await this.$refs.renameForm.validate();
      this.submitLoading = true;
      const { templateId } = this.dataset.datas;
      const { templateName } = this.rename.form;
      const params = { templateId, templateName };
      templateRename(params)
        .then(() => {
          this.$message.success("修改成功！");
          this.closeHandler(true);
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.template-rename-dialog {
}
</style>