var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container custom-template-list",
      style: {
        width: _vm.sidebar.opened
          ? "calc(100vw - 330px)"
          : "calc(100vw - 140px)",
      },
    },
    [
      _c("div", { staticClass: "header-line" }, [
        _vm.showSider
          ? _c("i", {
              staticClass: "el-icon-arrow-left arrow-icon",
              on: {
                click: function ($event) {
                  return _vm.setTransformX("left")
                },
              },
            })
          : _vm._e(),
        _c("div", { staticClass: "tabs-part" }, [
          _c(
            "div",
            {
              staticClass: "tabs-container",
              style: {
                transform: `translateX(${_vm.translateX}px)`,
              },
            },
            _vm._l(_vm.templateList, function (item) {
              return _c(
                "span",
                {
                  key: item.itemId,
                  class: ["single-tab", item.active && "single-tab-active"],
                  attrs: { title: item.itemName },
                  on: {
                    click: function ($event) {
                      return _vm.setTabActive(item)
                    },
                  },
                },
                [_vm._v("\n          " + _vm._s(item.itemName) + "\n        ")]
              )
            }),
            0
          ),
        ]),
        _vm.showSider
          ? _c("i", {
              staticClass: "el-icon-arrow-right arrow-icon",
              on: {
                click: function ($event) {
                  return _vm.setTransformX("right")
                },
              },
            })
          : _vm._e(),
        _c(
          "div",
          { staticClass: "dropdown-part" },
          [
            _c(
              "r-button",
              { attrs: { plain: "" }, on: { click: _vm.addNewHandler } },
              [_vm._v("新增模板")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "card-list", on: { scroll: _vm.containerScroll } },
        _vm._l(_vm.templateList, function (item) {
          return _c(
            "div",
            {
              key: item.itemCode,
              ref: `group_${item.itemId}`,
              refInFor: true,
              staticClass: "group-container",
              attrs: { id: `group_${item.itemId}` },
            },
            [
              _c("div", { staticClass: "group-label" }, [
                _vm._v("\n        " + _vm._s(item.itemName) + "\n      "),
              ]),
              !item.list.length
                ? _c("div", { staticClass: "no-card" }, [_vm._v("暂无数据")])
                : _c(
                    "div",
                    { staticClass: "card-container" },
                    [
                      _c(
                        "el-row",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { gutter: 30 },
                        },
                        _vm._l(item.list, function (single, singleIndex) {
                          return _c(
                            "el-col",
                            { key: single.itemId, attrs: { span: 8 } },
                            [
                              _c("TemplateCard", {
                                ref: "singleCardRef",
                                refInFor: true,
                                attrs: {
                                  dataset: single,
                                  index: singleIndex,
                                  templateList: _vm.templateList,
                                },
                                on: {
                                  success: _vm.initDatas,
                                  toDetail: _vm.jumpToDetail,
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }